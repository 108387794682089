<template>
  <div class="pole-list">
    <!-- excuse / info message -->
    <div class="info-msg">Some error in auto finding, please select the pole.</div>
    <!-- pole list -->
    <v-card class="mx-auto ma-5" max-width="50%" outlined>
      <v-container>
        <!-- serach pole -->
        <v-text-field
          clearable
          flat
          solo
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search pole"
          v-model="search_key"
          @input="search"
        ></v-text-field>
        <v-divider></v-divider>
        <!-- list pole  -->
        <v-list style="height: 420px; overflow: auto">
          <v-list-item v-for="(pole, i) in temp_defect_poles" :key="i" dense class="list-item" @click.prevent="emitPole(pole)" >
            <v-list-item-content>{{i+1}}. {{pole.name}}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
    <div class="info-msg">
      <v-btn text color="appcolor" @click.prevent="$emit('cancel')">Cancle</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "defect-pole-filter",
  props: ["defectPoleFilterProps"],
  data() {
    return {
      defect_poles: [],
      temp_defect_poles: [],
      search_key: ""
    };
  },
  methods: {
    // set pole list array
    setPoleList() {
      this.defect_poles = this.defectPoleFilterProps;
      this.temp_defect_poles = this.defect_poles;
    },

    // filter user list by all levels and search key
    search() {
      if (this.search_key == "" || !this.search_key) {
        this.temp_defect_poles = this.defect_poles;
      } else {
        this.temp_defect_poles = this.defect_poles.filter(x =>
          x.name.match(this.search_key)
        );
      }
    },

    // emit the selected pole
    emitPole(pole) {
      this.$emit('selected_pole', pole)
    }
  },
  watch: {
    // watch any change in pole list and update pole list array 
    defectPoleFilterProps: function() {
      this.setPoleList();
    }
  },
  mounted() {
    // set pole array list
    this.setPoleList();
  },
};
</script>

<style scoped>
.info-msg {
  color: #6d6969;
  text-align: center;
}
.list-item {
  cursor: pointer;
}
</style>