<template>
  <div id="tnb-images">
    <v-container fluid v-if="!show_pole_filter">
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>

      <span
        v-show="pole_data"
        :title="(disable_defect_button) ? 'grid file not found! for this inspection' : 'update defects for this inspection'"
        class="right-align"
        style="float: right; display: inline-flex"
      >
        <v-btn
          :disabled="disable_defect_button"
          small
          class="nav-btn"
          color="primary"
          @click.prevent="openDefectMarking"
        >
          Update Defect
          <v-icon v-show="disable_defect_button" right dark>mdi-information-variant</v-icon>
        </v-btn>
      </span>

      <!-- filter -->
      <v-card class="mx-auto" outlined>
        <div class="card-sub-label">Filter by:</div>

        <v-row flex class="fliter-box">
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="3"
            class="custom-col"
            v-for="(filter, i) in Object.keys(level_list)"
            :key="i"
          >
            <v-autocomplete
              dense
              :items="level_list[filter]"
              item-text="name"
              item-value="_id"
              :label="filter"
              v-model="filter_items[filter]"
              outlined
              clearable
              color="appcolor"
              @input="selectSeacrhChange(filter_items[filter], filter)"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card>

      <!-- pole list and pole images layout-->
      <v-layout wrap>
        <!-- pole list -->
        <v-flex sm12 md2>
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <v-text-field
                clearable
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search pole"
                v-model="search_key"
                @input="search"
              ></v-text-field>
              <v-divider></v-divider>
              
              <v-list style="height: 420px; overflow: auto">

                <v-progress-circular
                v-show="pole_list_loader"
                :size="50"
                color="primary"
                indeterminate
                class="postion-center"
              ></v-progress-circular>
                <v-list-item v-for="(pole, i) in temp_poles" :key="i" dense v-show="!pole_list_loader">
                  <v-list-item-content @click="getPoleDetails(pole._id)">{{i+1}}. {{pole.name}}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card>
        </v-flex>

        <!-- pole data -->
        <v-flex sm12 md3>
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined>
            <v-container v-if="pole_data">
              <h3>{{pole_data.name}}</h3>

              <div class="sub-head">
                <v-row style="font-size: 12px" class="font-weight-light">
                  <v-col cols="12" md="12" sm="12" style="padding: 5px 10px">
                    <v-chip
                      :color="pole_data.chip_color"
                      text-color="white"
                      x-small
                    >{{getMetadata(pole_data.metadata, "visual_condition")}}</v-chip>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" style="padding: 5px 10px">
                    <v-icon x-small>mdi-calendar</v-icon>
                    {{pole_data.updatedAt | formate_ddmmyyyy}}
                  </v-col>
                  <v-col cols="12" md="6" sm="6" style="padding: 5px 10px">
                    <v-icon x-small>mdi-map-marker</v-icon>
                    {{getMetadata(pole_data.metadata, "lat") | round_of}}, {{getMetadata(pole_data.metadata, "lng") | round_of}}
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <div class="_tab">
                <a
                  class="side-line"
                  @click="image_mode= 'visual', filter_com_eqp(), set_images()"
                  :class="{active: image_mode == 'visual'}"
                >
                  <div class="row">
                    <div class="column_img">
                      <v-img
                        src="@/assets/icon-visual.png"
                        aspect-ratio="1"
                        max-width="45px"
                        max-height="45px"
                      ></v-img>
                    </div>
                    <div class="column_label">
                      <h6>VISUAL</h6>
                      <p
                        style="font-size:10px"
                      >Status: {{getMetadata(pole_data.metadata, "visual_condition")}}</p>
                    </div>
                  </div>
                </a>
                <a
                  @click="image_mode= 'thermal', filter_com_eqp(), set_images()"
                  :class="{active: image_mode == 'thermal'}"
                >
                  <div class="row">
                    <div class="column_img">
                      <v-img
                        src="@/assets/icon-thermal-2.png"
                        aspect-ratio="1"
                        max-width="45px"
                        max-height="45px"
                      ></v-img>
                    </div>
                    <div class="column_label">
                      <h6>THERMAL</h6>
                      <p
                        style="font-size:10px"
                      >Status: {{getMetadata(pole_data.metadata, "thermal_condition")}}</p>
                    </div>
                  </div>
                </a>
              </div>
              <v-divider></v-divider>
              <button class="custom_button">ALL PHOTOS</button>
              <v-divider></v-divider>
              <button class="custom_button">COMPONENT / EQUIPMENT</button>
              <v-divider></v-divider>
              <div class="list-com-eqp">
                <table>
                  <tr v-for="(item, i) of temp_com_eqp" :key="i">
                    <td :class="['oval-status', 'status-'+getMetadata(item.metadata, 'severity')]">
                      {{item.name}}
                      <ul
                        v-if="(getCollection(item.collections, 'defects')).length > 0"
                        class="ul-defect"
                      >
                        <li
                          v-for="(defect, j) of getCollection(item.collections, 'defects')"
                          :key="j"
                        >{{(!defect.name) ? '' : defect.name}}</li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
            </v-container>
            <v-container v-if="!pole_data" class="no_data_container">
              <span>No pole selected</span>
            </v-container>
          </v-card>
        </v-flex>

        <!-- pole images -->
        <v-flex sm12 md7>
          <v-card class="mx-auto ma-5" outlined height="495px">
            <viewer
              :options="options"
              :images="images"
              @inited="inited"
              class="viewer"
              ref="viewer"
            >
              <template slot-scope="scope">
                <figure class="images">
                  <div class="image-wrapper" v-for="({thum_nail, url}, i) in scope.images" :key="i">
                    <img
                      style="display: none"
                      class="image"
                      :src="thum_nail"
                      :data-source="url"
                      alt="No Image Found"
                      @error="imageError"
                    />
                  </div>
                </figure>
              </template>
            </viewer>
          </v-card>
        </v-flex>
      </v-layout>

      <!-- full screen modal to view image -->
      <v-dialog
        v-model="viewImageDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card dark>
          <v-card-title>
            <v-btn icon dark @click="viewImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text icon dark title="download">
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-btn>
            <v-btn text icon dark title="update">
              <v-icon>mdi-update</v-icon>
            </v-btn>
            <v-btn text icon dark title="delete">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-img :src="viewImagleUrl" max-width="100vw" max-height="100vh">
              <!-- image loader  -->
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="appcolor lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-container>
        </v-card>
      </v-dialog>

      <customDialog :dialogProps="deleteDialogProps">
        <!-- title card -->
        <span slot="card-title" class="captalize">Delete Confirmation</span>

        <!-- card body -->
        <span slot="card-body">Are you sure you want to delete the selected !</span>

        <!-- card action buttons -->
        <span slot="card-action">
          <v-btn text @click="dialogProps.dialog = false">Cancel</v-btn>
          <v-btn text @click="dialogProps.dialog = false">Delete</v-btn>
        </span>
      </customDialog>
    </v-container>
    <v-container v-if="show_pole_filter && defect_data">
      <defectPoleFilter
      :defectPoleFilterProps="defect_data.poles"
      @selected_pole="poleSelected"
      @cancel="show_pole_filter = !show_pole_filter"
      ></defectPoleFilter>
    </v-container>
  </div>
</template>

<script>
import Mixins from "@/mixin";
import AdminAPI from "@/services/admin_api";
import customDialog from "@/components/custom-dialog";
import dbProject from "@/db_project";
import defectPoleFilter from "@/components/defect-pole-filter";

export default {
  name: "tnb-defect-review",
  mixins: [Mixins],
  components: {
    customDialog,
    defectPoleFilter
  },
  data() {
    return {
      // breadcrumb
      breadcrumbItems: [
        {
          text: "TNB - Images",
          disabled: false,
          to: { name: "tnb-images" },
          exact: true
        }
      ],

      // zone, state, subzone, station, source, feeder, section, inspection
      level_list: {
        zone: [],
        state: [],
        subzone: [],
        station: [],
        source: [],
        feeder: [],
        section: [],
        inspection: []
      },
      // pole list
      poles: [],
      temp_poles: [],
      pole_data: null,
      temp_com_eqp: [],
      pole_list_loader: false,

      // pole filter key
      search_key: "",

      // filter query data
      filter_items: {
        zone: null,
        state: null,
        subzone: null,
        station: null,
        source: null,
        feeder: null,
        section: null,
        inspection: null
      },

      // image porperty
      image_mode: "visual",
      images: [],
      select_all_images: false,
      enable_select_all_option: false,
      selected_images_active: [],
      viewImageDialog: false,
      viewData: null,
      viewImagleUrl: "",

      // Delete confirm model props
      deleteDialogProps: {
        dialog: false,
        width: "600px"
      },

      // image viewer
      options: {
        inline: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: "data-source"
      },

      // defect var
      disable_defect_button: false,
      defect_data: null,
      show_pole_filter: false,
      defect_project_id: null,
      defect_pole_id: null
    };
  },
  methods: {
    // route pole to defect marking
    openDefectMarking() {
      if (this.defect_project_id && this.defect_pole_id) {
        this.show_pole_filter = false;
        let routeData = this.$router.resolve({path: `/defect-page/${this.defect_project_id}/poles/${this.defect_pole_id}`});
        window.open(routeData.href, "_blank");
      } else {
        let filtered_data = this.filterDefectedPole(this.pole_data.name);
        if (filtered_data) {
          this.defect_project_id = filtered_data.project_id;
          this.defect_pole_id = filtered_data.id;
          this.openDefectMarking();
        } else {
          this.show_pole_filter = true;
        }
      }
    },

    // Pole auto filter
    filterDefectedPole(pole_name) {
      return this.defect_data.poles.find(obj => obj.name == pole_name);
    },

    // manual selected pole from defected data
    poleSelected(pole) {
      this.defect_project_id = pole.project_id;
      this.defect_pole_id = pole.id;
      this.openDefectMarking();
    },

    // handel image usrc error
    imageError(e) {
      return (e.target.src = require("@/assets/no_image_found.png"));
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    // intialize image array
    async set_images() {
      this.images = [];
      const collection = this.getCollection(
        this.pole_data.collections,
        "images"
      );
      for (let i = 0; i < collection.length; i++) {
        if (
          this.image_mode == this.getMetadata(collection[i].metadata, "type")
        ) {
          this.images.push({
            thum_nail: this.getMetadata(collection[i].metadata, "thumb_url"),
            url: this.getMetadata(collection[i].metadata, "url")
          });
        }
      }
    },

    // montior any selected image and enable the delete option
    monitor_select_image() {
      const anyTrue = this.selected_images_active.find(x => x);
      this.enable_select_all_option = anyTrue ? true : false;
    },

    // select all image toggle function
    toggle_select_all(select_all) {
      if (select_all) {
        for (let i = 0; i < this.selected_images_active.length; i++) {
          this.selected_images_active[i] = true;
        }
        this.enable_select_all_option = true;
      } else {
        for (let i = 0; i < this.selected_images_active.length; i++) {
          this.selected_images_active[i] = false;
        }
        this.enable_select_all_option = false;
      }
    },

    // open image view
    async openImage(pole_data) {
      this.viewData = pole_data;
      let _url = await this.getMetadata(this.viewData.metadata, "url");
      this.viewImagleUrl = _url;
      this.viewImageDialog = true;
    },

    // delete images
    delete_images() {
      let anyTrue = this.selected_images_active.map((x, i) => {
        if (x) {
          return i;
        }
      });
      anyTrue = anyTrue.filter(y => y != undefined);

    },

    // search pole
    search() {
      if (this.search_key == "" || !this.search_key) {
        this.temp_poles = this.poles;
      } else {
        this.temp_poles = this.poles.filter(x => x.name.match(this.search_key));
      }
    },

    // fetch defect data from serve
    async getDefectData(inspection) {
      let response = await AdminAPI.get(`inspectionGridDetails/${inspection}`);
      response = response.data.status ? response.data.gridData : null;
      return response;
    },

    // get select level base list
    async selectSeacrhChange(value, ind) {
      let getData;
      this.clearAll();
      if (typeof value !== "undefined") {
        if (ind == "zone") {
          getData = await AdminAPI.get(
            `/zones/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.state = getData.data.states;
        }
        if (ind == "state") {
          getData = await AdminAPI.get(
            `/states/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.subzone = getData.data.places;
        }
        if (ind == "subzone") {
          getData = await AdminAPI.get(
            `/subzones/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.station = getData.data.places;
        }
        if (ind == "station") {
          getData = await AdminAPI.get(
            `/stations/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.source = getData.data.places;
        }
        if (ind == "source") {
          getData = await AdminAPI.get(
            `/sources/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.feeder = getData.data.places;
        }
        if (ind == "feeder") {
          getData = await AdminAPI.get(
            `/feeders/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.section = getData.data.places;
        }
        if (ind == "section") {
          getData = await AdminAPI.get(
            `/sections/${value}?showonly=name&getlist=by_id`
          );
          this.level_list.inspection = getData.data.places;
        }
        if (ind == "inspection") {
          this.pole_list_loader = true;
          getData = await AdminAPI.get(
            `/inspections/${value}?showonly=name&getlist=by_id`
          );
          this.defect_data = await this.getDefectData(value);
          await dbProject.createDatabase(this.defect_data);

          this.poles = getData.data.places;
          this.temp_poles = this.poles;
          this.pole_list_loader = false;
        }
        this.disable_defect_button = this.defect_data == null ? true : false;
      } else {
        this.emptyTextBoxes(ind, this.level_list);
        this.emptyTextBoxes(ind, this.filter_items, "val");
      }
    },
    // get selected pole details
    async getPoleDetails(poleId) {
      this.pole_data = null;
      this.images = [];
      this.defect_pole_id = null;
      this.defect_project_id = null;

      let poleDetails = await AdminAPI.get(`/poles/${poleId}`);
      this.pole_data = poleDetails.data;
      let comp = this.getCollection(this.pole_data.collections, "components");
      let eqp = this.getCollection(this.pole_data.collections, "equipments");
      const com_eqp = comp.concat(eqp);
      this.pole_data.com_eqp = com_eqp;
      this.image_mode = "visual";
      this.filter_com_eqp();
      this.pole_data.chip_color = this.getMetadata(
        this.pole_data.metadata,
        "visual_condition"
      );
      this.set_images();
    },
    // clear pole data, defect data, images
    clearAll() {
      this.poles = null;
      this.temp_poles = null;
      this.pole_data = null;
      this.defect_data = null;
      this.images = [];
    },
    // filter comonent and equpiment based on mode
    filter_com_eqp() {
      this.temp_com_eqp = this.pole_data.com_eqp.filter(
        x => this.getMetadata(x.metadata, "type") == this.image_mode
      );
    }
  },
  async mounted() {
    // this.set_images();
    let zoneList = await AdminAPI.get(`/zones`);
    this.level_list.zone = zoneList.data;
  }
};
</script>

<style scoped>
.no_data_container {
  height: 490px;
  background-color: #bdbdbd;
}
.no_data_container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom_button::before {
  content: "";
  background: url("../assets/bezier-curve-solid.svg") no-repeat;
  color: #808368;
  width: 14px;
  height: 14px;
  float: left;
  margin: auto 6px auto 0;
  position: relative;
  top: 3px;
}
.custom_button::after {
  content: "";
  background: url("../assets/angle-right-solid.svg") no-repeat;
  color: #808368;
  width: 14px;
  height: 14px;
  float: right;
  margin: auto 6px auto 0;
  position: relative;
  top: 3px;
}
.custom_button {
  height: 35px;
  font-size: 12px;
  width: 100%;
  text-align: left;
  outline: none !important;
}
.list-com-eqp {
  overflow-y: scroll;
  height: 247px;
  font-size: 12px;
}
._tab {
  overflow: hidden;
  background-color: #ffffff;
}
._tab a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  padding-bottom: 0;
  text-decoration: none;
  font-size: 17px;
  width: 50%;
  border-bottom: 3px solid transparent;
}

._tab a.active {
  border-bottom: 3px solid red;
}
.side-line {
  position: relative;
}
.side-line::after {
  content: "";
  height: 70%;
  width: 1px;

  position: absolute;
  right: 0;
  top: 10px;

  background-color: #a7a7a7;
}
.row {
  display: flex;
  width: 100%;
}
.column_img {
  flex: 30%;
}
.column_label {
  flex: 50%;
}

.card-sub-label {
  color: #808368;
  margin: 0 10px;
}
.fliter-box {
  margin: 0 auto;
}
.custom-col {
  padding: 0 12px;
}
.pull-left {
  margin-left: 0 !important;
}
.float-right {
  float: right !important;
}
.image-card {
  max-height: 435px;
  overflow-y: auto;
}
.image-hover {
  background-color: rgba(90, 90, 90, 0.6);
  color: #ffffff;
  height: 100%;
}

.select-box {
  border: 3px solid #808368;
}
.select-button {
  position: absolute;
  top: 0;
  left: 0px;
}
.select-button > .v-input {
  margin: 0;
  padding: 0;
}
.select-button > .v-input .v-label {
  color: #ffffff !important;
}

.image-hover .hover-action-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: center;
  margin: auto;
}
.image-card .hover-action-button-view {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.image-card-title {
  height: 12%;
  padding-top: 0;
  padding-bottom: 0;
}
.image-card-add-button {
  margin: 10px;
  float: right;
}

.oval-status::before {
  content: " \25CF";
  font-size: 16px;
  margin-right: 11px;
}
.status-Normal::before {
  color: #5cac00;
}
.status-Low::before {
  color: #5b98de;
}
.status-Critical::before {
  color: #d91700;
}
.status-Cautious::before {
  color: #f0be47;
}
.ul-defect {
  margin-left: 10px;
}
.ul-defect li {
  height: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.c-viewer-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.c-viewer-button {
  outline: none !important;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  padding: 1%;
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  z-index: 5;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}
.postion-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}
</style>